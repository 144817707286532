import React from 'react'
import styled from 'styled-components'
import Translate from '../components/typography/Translate'
import H1 from '../components/typography/H1'
import Base from './Base'
import HeadingSection from '../components/layout/HeadingSection'
import MainContainer from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'

const Card = styled(CardBody)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
`

const NotFound: React.FC = () => (
  <Base isIE={false}>
    <HeadingSection>
      <MainContainer>
        <Card>
          <H1><Translate id="title.404" /></H1>
          <p><Translate id="title.404_subtitle" /></p>
        </Card>
      </MainContainer>
    </HeadingSection>
  </Base>
)

export default NotFound
