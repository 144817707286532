import React from 'react'
import { IntlProvider } from 'react-intl/dist/react-intl'
import { ThemeProvider } from 'styled-components'
import { BaseCSS, GridThemeProvider } from 'styled-bootstrap-grid'
import moment from 'moment'
import theme, { gridTheme } from '../../theme'
import GlobalStyle from '../layout/GlobalStyle'
import translations from '../../translations'
import Helmet from 'react-helmet'

interface RootProviderProps {
  locale: string
}

const RootProvider: React.FC<RootProviderProps> = ({ children, locale }) => {
  moment.locale(locale)

  return (
    <IntlProvider locale={locale} messages={translations[locale]}>
      <Helmet titleTemplate="%s" defaultTitle="Dierentuintickets">
        <html lang={locale} />
      </Helmet>
      <ThemeProvider theme={theme}>
        <GridThemeProvider gridTheme={gridTheme}>
          <>
            <BaseCSS />
            <GlobalStyle />
            {children}
          </>
        </GridThemeProvider>
      </ThemeProvider>
    </IntlProvider>
  )
}

export default RootProvider
