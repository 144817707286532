import React from 'react'
import styled from 'styled-components'
import H1 from '../components/typography/H1'
import { Vendor } from '../models'
import Base from './Base'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import MainContainer from '../components/layout/MainContainer'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import CardBody from '../components/cards/CardBody'
import vendorApi from '../api/vendorApi'
import VendorCard from '../components/vendors/VendorCard'
import Helmet from 'react-helmet'

const IntroText = styled.p`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

interface VendorOverviewProps {
  vendors: Vendor[]
}

class VendorOverview extends React.Component<VendorOverviewProps> {
  static async getInitialProps() {
    const vendorResponse = await vendorApi.getVendors()

    return {
      vendors: vendorResponse.data.items
    }
  }

  render = () => {
    const { vendors } = this.props
    return (
      <Base isIE={false}>
        <Helmet>
          <title>Alle deelnemende parken | Dierentuintickets.nl</title>
          <meta name="description" content="Bekijk alle deelnemende parken, kies jouw korting en koop snel jouw tickets." />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <HeadingSection>
          {vendors.length > 0 && typeof vendors[0].background_image?.jumbotron !== 'undefined' && (
            <HeadingBackground
              backgroundImage={vendors[0].background_image?.jumbotron}
            />
          )}
          <MainContainer>
            <Breadcrumbs
              parts={[
                { label: 'Parken', slug: false }
              ]}
            />
            <CardBody>
              <H1>Deelnemende parken</H1>
              <IntroText>Extra voordelig naar de dierentuin.</IntroText>
              <Row>
                {vendors.map((vendor) => (
                  <Col md={6} key={vendor.id}>
                    <VendorCard vendor={vendor} />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </MainContainer>
        </HeadingSection>
      </Base>
    )
  }
}

export default VendorOverview
