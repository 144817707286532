import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: ${({ theme }) => theme.color.secondary };
  color: #fff;
  padding: 1rem;
  border-radius: ${({ theme }) => `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`};

  ${media.tablet`
    padding: 0.5rem 2rem;
  `}
`
