import React from 'react'
import styled from 'styled-components'
import NavigationBar from '../layout/NavigationBar'
import DeleteButton from '../buttons/DeleteButton'
import { media } from 'styled-bootstrap-grid'

const ModalWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 1050;
  display: flex;
  align-items: baseline;
`

const ModalContent = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  width: 95%;
  overflow: hidden;
  margin: 40px auto;
  box-sizing: border-box;
  z-index: 1;
  padding: 20px;
  ${media.tablet`
    margin: 80px auto;
    width: 40%;
  `}
`

const ModalHeader = styled.div`
  background: ${({ theme }) => theme.color.neutralDark};
  border-radius: ${({ theme }) => `${theme.borderRadius.default} ${theme.borderRadius.default} 0 0`};
  padding: 0 ${({ theme}) => theme.padding.lg};
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`

const ModalBody = styled.div`
  border-radius: ${({ theme }) => `0 0 ${theme.borderRadius.default} ${theme.borderRadius.default}`};
  background: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.padding.md};
  ${({ theme }) => media.tablet`
    padding: ${theme.padding.xl};
  `}
`

const ModalBackground = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background: rgba(255, 255, 255, 0.6);
`

const CloseButton = styled(DeleteButton)`
  position: absolute;
  top: 0;
  right: 0;
  background: ${({ theme }) => theme.color.neutralLight};
  border-radius: 50%;
  padding: 8px;
  svg {
    fill: ${({ theme }) => theme.color.neutralMedium};
  }
`

interface ModalProps {
  isVisible: boolean
  onClose: () => void
}

const Modal: React.FC<ModalProps> = ({ isVisible, children, onClose }) => {
  if (!isVisible) {
    return null
  }

  return (
    <ModalWrapper>
      <ModalContent>
        <CloseButton onClick={onClose} />
        <ModalHeader>
          <NavigationBar />
        </ModalHeader>
        <ModalBody>
          {children}
        </ModalBody>
      </ModalContent>
      <ModalBackground />
    </ModalWrapper>
  )
}

export default Modal
