import React from 'react'
import styled from 'styled-components'
import { CenteredContainer } from '../layout/MainContainer'
import H2 from '../typography/H2'
import SubscriptionForm from './SubscriptionForm'
import Row from '../layout/Row'
import Col from '../layout/Col'
import Small from '../typography/Small'

const SubscriptionInfo = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.margin.lg};
  color: #fff;
  ${H2} {
    color: #fff;
  }
`

const Section = styled.section`
  background-color: ${({ theme }) => theme.color.neutralMedium};
  padding: ${({ theme }) => `${theme.padding.lg} 0`};
`

const SuccessLabel = styled.div`
  color: #fff;
`

const SubscriptionSection: React.FC = () => {
  const [success, setSuccess] = React.useState(false)

  return (
    <Section>
      <CenteredContainer>
        <SubscriptionInfo>
          <H2>Wil je geen enkele aanbieding missen?</H2>
          <p>
            Onze aanbiedingen zijn slechts beperkte tijd geldig.
            Dankzij onze e-mail hoef je niks te missen! Meld je aan.
          </p>
        </SubscriptionInfo>
        <Row>
          <Col md={8} mdOffset={2}>
            <SubscriptionForm
              buttonLabel="AANMELDEN"
              onSuccess={() => setSuccess(true)}
            />
            {success && (
              <SuccessLabel>
                <Small>Bedankt voor je inschrijving!</Small>
              </SuccessLabel>
            )}
          </Col>
        </Row>
      </CenteredContainer>
    </Section>
  )
}

export default SubscriptionSection
