import React, { useState } from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import NavBurgerIcon from '@fortawesome/fontawesome-free/svgs/solid/bars.svg'
import Col from './Col'
import Row from './Row'
import { Link } from 'react-router-dom'

const Logo = styled.img`
  margin: ${({ theme }) => `${theme.margin.md} 0 0 0`};
  height: 40px;
  z-index: 50;

  ${media.tablet`
    position: relative;
  `}
`

const LogoCol = styled(Col)`
  position: relative;
  text-align: center;
  margin-bottom: 1rem;

  ${media.tablet`
    text-align: left;
    margin-bottom: 0;
  `}
`

const RoundedLogoBorder = styled.div`
  position: absolute;
  border-bottom: 1px solid ${({ theme }) => theme.color.neutralDark};
  display: none;

  top: 50px;
  left: 45px;
  width: 150px;
  height: 25px;
  background: ${({ theme }) => theme.color.neutralDark};
  border-radius: 0 0 25px 25px;
  content: '';
  z-index: 10;
  
  ${media.tablet`
    display: block;
  `}
`

const NavBurger = styled(NavBurgerIcon)`
  cursor: pointer;
  position: absolute;
  left: 1.5rem;
  top: 1.25rem;
  width: 1.5rem;
  fill: white;
  
  ${media.tablet`
    display: none;
  `}
`

const NavCol = styled(Col)<any>`
  display: ${({ isOpen }) => isOpen ? 'block' : 'none'};
  align-self: center;
  font-weight: 700;

  padding-bottom: ${({ isOpen }) => isOpen ? '0.5rem' : '0'};

  ${media.tablet`
    text-align: right;
    padding: 0.25em 0;
    display: block
  `}
`

const NavigationBar: React.FC = ({ children }) => {
  const [isOpen, setHidden] = useState(false)
  const handleClick = () => setHidden(!isOpen)

  return (
    <Row justifyContent="between">
      <LogoCol col={12} md={4}>
        {typeof children !== 'undefined' && (
          <NavBurger onClick={handleClick} />
        )}
        <Link to="/">
          <Logo src="/img/logo-dierentuintickets.svg" />
        </Link>
        <RoundedLogoBorder />
      </LogoCol>
      <NavCol col={12} md={8} isOpen={isOpen}>
        {children}
      </NavCol>
    </Row>
  )
}

export default NavigationBar
