import React from 'react'
import styled from 'styled-components'
import SignInIcon from '../../../public/img/icons/sign-in.svg'
import SpecialsIcon from '../../../public/img/icons/specials.svg'
import ShopIcon from '../../../public/img/icons/shop.svg'
import SelectIcon from '../../../public/img/icons/select.svg'
import EticketIcon from '../../../public/img/icons/eticket.svg'

const ListKey = styled.div`
  font-family: ${({ theme }) => theme.font.title};
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  color: #fff;
  background-color: ${({ theme }) => theme.color.neutralMedium};
  font-size: 1.5rem;
  font-weight: bold;
  padding-left: 15px;
  padding-right: 15px;
  width: 40px;
  height: 40px;
  padding-top: 2px;
`

const Text = styled.div`
  position: relative;
  flex-grow: 1;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #fff;
  font-weight: bold;
  padding: ${({ theme }) => `${theme.padding.md} 80px ${theme.padding.md} ${theme.padding.lg}`};
`

const StepIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  svg {
    fill: ${({ theme }) => theme.color.secondary};
    width: 50px;
    top: .125em;
    position: relative;
  }
`

const Step = styled.li`
  display: flex;
  margin-bottom: ${({ theme }) => theme.margin.sm};
`

type Icon = 'sign-in' | 'shop' | 'specials' | 'select' | 'eticket'

interface HowToStepProps {
  icon: Icon
  step: number
}

const getIcon = (icon: Icon) => {
  switch (icon) {
    case 'sign-in': {
      return <SignInIcon />
    }
    case 'specials': {
      return <SpecialsIcon />
    }
    case 'eticket': {
      return <EticketIcon />
    }
    case 'shop': {
      return <ShopIcon />
    }
    case 'select': {
      return <SelectIcon />
    }
    default: {
      return null
    }
  }
}

const HowToStep: React.FC<HowToStepProps> = ({ children, step, icon }) => (
  <Step>
    <ListKey>{step}</ListKey>
    <Text>
      {children}
      <StepIcon>{getIcon(icon)}</StepIcon>
    </Text>
  </Step>
)

export default HowToStep
