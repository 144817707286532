import React from 'react'
import styled from 'styled-components'
import Translate from '../typography/Translate'
import { Link } from 'react-router-dom'

const FooterLinksWrapper = styled.div`
  text-align: center;
  color:  ${({ theme }) => theme.textColor.default };
  padding-top: 1rem;
`

const FooterLink = styled(Link)<any>`
  display: inline-block;
  padding: 0 1rem;
`

const FooterLinks: React.FC = () => (
  <FooterLinksWrapper>
    <FooterLink to="/cookieverklaring">
      <Translate id="title.footer_cookie_link"/>
    </FooterLink>
    <FooterLink to="/privacy-statement">
      <Translate id="title.footer_privacy_link"/>
    </FooterLink>
    <FooterLink to="/voorwaarden-en-disclaimer">
      <Translate id="title.footer_actievoorwarden_link"/>
    </FooterLink>
    <FooterLink to="/faq">
      <Translate id="title.footer_faq_link"/>
    </FooterLink>
    <FooterLink to="/contact">
      <Translate id="title.footer_contact_link"/>
    </FooterLink>
  </FooterLinksWrapper>
)

export default FooterLinks
