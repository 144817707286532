import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import DiscountLabel from '../offers/DiscountLabel'
import { ImageSizes } from '../../models'

const VendorLogo = styled.img`
  position: absolute;
  top: -10px;
  right: -10px;
  width: 100px;
  height: 100px;
  z-index: 50;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  ${media.tablet`
    width: 100px;
    height: 100px;
  `}
`

const MainImage = styled.img`
  background: grey;
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.default};
`

const ImageWrapper = styled.div`
  position: relative;
`

interface VendorImageProps {
  image: ImageSizes
  label?: string
  logo?: string
}

const VendorImage: React.FC<VendorImageProps> = ({ image, label, logo }) => (
  <ImageWrapper>
    <MainImage src={image?.thumbnail} />
    {typeof logo !== 'undefined' && (
      <VendorLogo src={logo} />
    )}
    {typeof label !== 'undefined' && label !== '' && (
      <DiscountLabel>{label}</DiscountLabel>
    )}
  </ImageWrapper>
)

export default VendorImage
