import styled from 'styled-components'
import PrimaryButton from '../buttons/PrimaryButton'

export default styled(PrimaryButton)`
  position: absolute;
  bottom: -3.5rem;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 219px;
`
