import React from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import TextField from '../form/fields/TextField'
import Row from '../layout/Row'
import Col from '../layout/Col'
import SubmitButton from '../buttons/SubmitButton'
import subscriptionUtils from '../../utils/subscriptionUtils'
import { postSubscription } from '../../api/subscriptionApi'
import ErrorMessage from '../form/ErrorMessage'
import { media } from 'styled-bootstrap-grid'
import InlineLoader from '../loaders/InlineLoader'

interface SubscriptionFormProps {
  buttonLabel: string
  onSuccess?: () => void
}

interface SubscriptionFormValues {
  email: string
}

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
  ${media.tablet`
    margin-bottom: 0;
  `}
`

const SubscriptionForm: React.FC<SubscriptionFormProps> = ({ buttonLabel, onSuccess }) => (
  <Formik<SubscriptionFormValues>
    initialValues={{ email: '' }}
    onSubmit={({ email }, formikHelpers) => {
      return postSubscription(email)
        .then(() => {
          subscriptionUtils.setSubscribed()

          if (typeof onSuccess !== 'undefined') {
            onSuccess()
          }

          formikHelpers.resetForm()

          return true
        })
    }}
    validate={(values) => {
      const errors: { email?: string } = {}
      if (!values.email) {
        errors.email = 'Dit veld is verplicht'
      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = 'Dit is geen geldig e-mailadres'
      }

      return errors
    }}
  >
    {({ isSubmitting }) => (
      <Form>
        <Row>
          <Col md={8}>
            <FormGroup>
              <Field
                component={TextField}
                name="email"
                type="email"
                placeholder="Vul hier je e-mailadres in"
              />
              <ErrorMessage name="email" />
            </FormGroup>
          </Col>
          <Col md={4}>
            <SubmitButton type="submit" disabled={isSubmitting}>
              {isSubmitting && (
                <InlineLoader />
              )}
              {!isSubmitting && buttonLabel}
            </SubmitButton>
          </Col>
        </Row>
      </Form>
    )}
  </Formik>
)

export default SubscriptionForm
