import React, { useState } from 'react'
import styled from 'styled-components'
import { GroupType } from 'react-select'
import ContactForm from '../components/contact/ContactForm'

import H1 from '../components/typography/H1'
import H4 from '../components/typography/H4'
import P from '../components/typography/P'
import Base from './Base'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import HeadingSection from '../components/layout/HeadingSection'
import MainContainer from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'
import { media } from 'styled-bootstrap-grid'
import Translate from '../components/typography/Translate'
import TranslateHtml from '../components/typography/TranslateHtml'
import Select, { SelectOption } from '../components/form/Select'
import FormGroup from '../components/form/FormGroup'
import Helmet from 'react-helmet'

const Card = styled(CardBody)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
`

const TextDiv = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
  ${media.tablet`
    margin-bottom: 10;
    margin-top: 10;
  `}

  ${P} > a {
    word-wrap: break-word;
  }
`

const CustomSelect = styled(Select)`
  & .custom-select {
    &__placeholder {
      color: #757575;
    }
    &__control {
      border-color: #695603;
      border-radius: 0;

      &:hover {
        // force default behavior
        border-color: #695603;
      }
    }
    &__menu {
      border-radius: 0;
      margin-top: 0;
    }
    &__menuList {
      padding: 0;
    }
  }
`

export type OptionType = {
  value: string | number
  label: string
}

export default function ContactPage() {
  const contactParks: GroupType<OptionType> = {
    options: [
      { label: 'Algemene vragen', value: 'info@libemafunfactory.nl' },
      { label: 'Safaripark Beekse Bergen', value: 'gastenservicesafaripark@beeksebergen.nl' },
      { label: 'AquaZoo', value: 'info@aquazoo.nl' },
      { label: 'Dierenrijk', value: 'info@dierenrijk.nl' },
      { label: 'ZooParc', value: 'info@zooparc.nl' },
    ],
  }

  const [selectedPark, setSelectedPark] = useState<OptionType>(contactParks.options[0])

  return (
    <Base isIE={false}>
      <Helmet>
        <title>Contact | Dierentuintickets.nl</title>
        <meta name="robots" content="index, follow" />
      </Helmet>
      <HeadingSection>
        <MainContainer>
          <Card>
            <H1>Contact</H1>
            <TextDiv>
              <P>Dierentuintickets.nl is onderdeel van Libéma.</P>
            </TextDiv>
            <TextDiv>
              <H4>Neem contact op met Dierentuintickets.nl</H4>
              <P>
                <Translate id="text.contact_page_questions" />
              </P>
            </TextDiv>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <CustomSelect options={contactParks.options} onChange={(selected: OptionType) => setSelectedPark(selected)} classNamePrefix="custom-select" />
                </FormGroup>
              </Col>
            </Row>
            <ContactForm selectedContact={selectedPark} />
            {selectedPark && (
              <TextDiv>
                <H4>{selectedPark.label}</H4>
                <P>
                  E-mail: <a href={`mailto: ${selectedPark.value}`}>{selectedPark.value}</a>
                </P>
                <P>Algemene Telefoonnummer: 088-9000360</P>
              </TextDiv>
            )}
            <H4>Pers</H4>
            <P>Media kunnen contact opnemen met de afdeling Public Relations van</P>
            <P>
              <TranslateHtml id="text.contact_page_contact_details" />
            </P>
          </Card>
        </MainContainer>
      </HeadingSection>
    </Base>
  )
}
