import React from 'react'
import styled from 'styled-components'
import { Col, Row } from 'styled-bootstrap-grid'
import H4 from '../typography/H4'
import FacebookLogo from '../../../public/img/icons/fb-logo.png'
import InstagramLogo from '../../../public/img/icons/instagram-logo.png'
import Ideal from '../../../public/img/icons/ideal.svg'
import MasterCard from '../../../public/img/icons/mastercard.svg'
import Visa from '../../../public/img/icons/visa.svg'
import Translate from '../typography/Translate'

const IdealLogo = styled(Ideal)`
  width: 50px;
  height: 50px;
  margin: 0 1em;
`;

const MasterCardLogo = styled(MasterCard)`
  width: 50px;
  height: 50px;
  margin: 0 1em;
`;

const VisaLogo = styled(Visa)`
  width: 50px;
  height: 50px;
  margin: 0 1em;
`;

const SocialLogo = styled.img`
  height: 30px;
  width: 30px;
  margin: 0 1em;
`;

const IconsCol = styled(Col)<any>`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const Logos = styled.div`
  display: inline-block;
  width: 100%;
`

const SocialLink = styled.a`
  display: inline-block;
  margin: 0.5rem 1rem;
  span {
    font-size: 0.9rem;
  }
`

const ExternalLogos: React.FC = () => (
  <Row>
    <IconsCol col={12}>
      <H4>
        <Translate id="title.footer_secure_payment_heading"/>
      </H4>
      <Logos>
        <IdealLogo/>
        <MasterCardLogo/>
        <VisaLogo/>
      </Logos>
    </IconsCol>
  </Row>
)

export default ExternalLogos
