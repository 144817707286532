import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'
import Translate from '../typography/Translate'
import NavigationBar from './NavigationBar'
import Container from './Container'
import { getOfferIndexRoute, getVendorIndexRoute } from '../../routes'

const HeaderWrapper = styled.header`
  background: ${({ theme }) => theme.color.neutralDark};
  z-index: 10;
`

const NavItem = styled.div`
  display: block;
  padding: 0.5rem;
  ${media.tablet`
    padding: 0.5rem 1rem;
    display: inline-block;
  `}
`

const NavLink = styled(Link)`
  color: ${({ theme }) => theme.color.neutralLight};
  text-decoration: none;
  &:visited {
    color: ${({ theme }) => theme.color.neutralLight}
  }
  
  &:hover {
    color: ${({ theme }) => theme.color.primary};
    text-decoration: none;
  }
`

const Header: React.FC = () => (
  <HeaderWrapper>
    <Container>
      <NavigationBar>
        <NavItem>
          <NavLink to={getOfferIndexRoute()}>
            <Translate id="title.offers" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to="/hoe-werkt-het">
            <Translate id="title.how_it_works" />
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink to={getVendorIndexRoute()}>
            <Translate id="title.participating_parks" />
          </NavLink>
        </NavItem>
      </NavigationBar>
    </Container>
  </HeaderWrapper>
)

export default Header
