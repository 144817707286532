import styled, { css } from 'styled-components'

export const h3Styles = css`
  color: ${({ theme }) => theme.color.dark};
  font-size: 1.5rem;
  margin: ${({ theme }) => `0 0 ${theme.padding.md} 0`};
`

export default styled.h3`
  ${h3Styles}
`
