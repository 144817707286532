import React from 'react'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import MainContainer, { CenteredContainer } from '../components/layout/MainContainer'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import H2 from '../components/typography/H2'
import Carousel from '../components/carousel/Carousel'
import ContactInformation from '../components/vendors/ContactInformation'
import { Offer } from '../models'
import NotFound from './NotFound'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import VendorHeader from '../components/vendors/VendorHeader'
import CardBody from '../components/cards/CardBody'
import Center from '../components/layout/Center'
import H3 from '../components/typography/H3'
import RTEData from '../components/typography/RTEData'
import OfferConditions from '../components/offers/OfferConditions'
import OfferTicket from '../components/offers/OfferTicket'
import { getOffer } from '../api/offerApi'
import Base from './Base'
import OfferTitle from '../components/offers/OfferTitle'
import Helmet from 'react-helmet'

const BottomPartWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.lg};
`

interface OfferDetailProps extends RouteComponentProps<{ slug: string }> {
  offer: Offer
}

const WhiteSection = styled.section`
  background-color: #fff;
`

class OfferDetail extends React.Component<OfferDetailProps> {
  static async getInitialProps({ match }: OfferDetailProps) {
    try {
      const offerResponse = await getOffer(match.params.slug)
      
      return {
        offer: offerResponse.data
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { statusCode: 404 }
      }

      throw error
    }
  }

  render = () => {
    const { offer } = this.props

    if (typeof offer === 'undefined') {
      return <NotFound />
    }
  
    return (
      <Base isIE={false}>
        <Helmet>
          <title>{offer?.meta_title || offer.name}</title>
          <meta name="description" content={offer.meta_description} />
          <meta name="robots" content={offer.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
        </Helmet>
        <HeadingSection>
          {typeof offer.background_image?.jumbotron !== 'undefined' && (
            <HeadingBackground
              backgroundImage={offer.background_image?.jumbotron}
            />
          )}
          <MainContainer>
            <Breadcrumbs
              parts={[
                { slug: '/parken', label: 'Parken' },
                { slug: `/parken${offer.vendor?.slug}`, label: offer.vendor?.name }
              ]}
            />
            <CardBody>
              <VendorHeader
                name={offer?.vendor?.name}
                main_image={offer?.vendor.main_image}
                logo={offer?.vendor.logo_secondary}
                location={offer?.vendor.location}
              >
                <OfferTitle name={offer.name} validTo={offer.valid_to} />
              </VendorHeader>
            </CardBody>
          </MainContainer>
          <CenteredContainer>
            <Center>
              <H3>TICKET INFORMATIE</H3>
            </Center>
            <OfferTicket offer={offer} />
            <OfferConditions>
              <RTEData dangerouslySetInnerHTML={{ __html: offer.conditions || '' }} />
            </OfferConditions>
          </CenteredContainer>
        </HeadingSection>
        <WhiteSection>
          <MainContainer>
            <BottomPartWrapper>
              <Row>
                <Col col={12}>
                  <H2 centered={true}>Alvast een voorproefje?</H2>
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <Carousel gallery={offer?.vendor?.gallery} />
                </Col>
              </Row>
              <ContactInformation
                data={offer?.vendor?.description}
              />
            </BottomPartWrapper>
          </MainContainer>
        </WhiteSection>
      </Base>
    )
  }
}

export default OfferDetail
