import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'

export default styled.section`
  position: relative;
  width: 100%;
  background-color: ${({ theme }) => theme.color.neutralLight};
  padding-top: 200px;
  ${media.tablet`
    padding-top: 6rem;
  `}
`
