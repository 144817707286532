import React from 'react'
import styled from 'styled-components'
import { Offer, HomePage } from '../models'
import Base from './Base'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import MainContainer, { CenteredContainer } from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'
import OfferCard from '../components/offers/OfferCard'
import H1 from '../components/typography/H1'
import H3 from '../components/typography/H3'
import CardFooterButton from '../components/cards/CardFooterButton'
import { Link } from 'react-router-dom'
import pageApi from '../api/pageApi'
import RTEData from '../components/typography/RTEData'
import HowToSteps from '../components/howto/HowToSteps'
import { getOfferIndexRoute } from '../routes'
import Helmet from 'react-helmet'

const Introduction = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

interface HomeProps {
  page: HomePage
}

const Card = styled(CardBody)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
`

const HowToSection = styled.section`
  margin-top: ${({ theme }) => theme.margin.xl};
  margin-bottom: ${({ theme }) => theme.margin.xl};
`

const HighlightH3 = styled(H3)`
  width: 100%;
  background: ${({ theme }) => theme.color.neutralMedium};
  border-radius: ${({ theme }) => theme.borderRadius.top};
  text-align: center;
  text-transform: uppercase;
  padding: ${({ theme }) => theme.padding.sm};
  color: #fff;
`

class Home extends React.Component<HomeProps> {
  static async getInitialProps() {
    const page = await pageApi.getPage<HomePage>('/index')

    return {
      page: page.data
    }
  }

  render = () => {
    const { page } = this.props
    return (
      <Base isIE={false}>
        <Helmet>
          <title>{page?.meta_title || page.title}</title>
          <meta name="description" content={page.meta_description} />
          <meta name="robots" content={page.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
        </Helmet>
        <HeadingSection>
          <HeadingBackground
            backgroundImage={page.background_image.jumbotron}
          />
          <MainContainer>
            <Card>
              <H1>{page.title}</H1>
              <Introduction>
                <RTEData dangerouslySetInnerHTML={{ __html: page.description }} />
              </Introduction>
              <HighlightH3>Uitgelichte aanbiedingen</HighlightH3>
              <Row>
                {(this.props.page.offers || []).map((offer: Offer) => (
                  <Col md={6} key={offer.id}>
                    <OfferCard offer={offer} />
                  </Col>
                ))}
              </Row>
              <CardFooterButton as={Link} to={getOfferIndexRoute()}>
                Alle aanbiedingen
              </CardFooterButton>
            </Card>
          </MainContainer>
        </HeadingSection>
        <HowToSection>
          <CenteredContainer>
            <Row>
              <Col md={6}>
                <RTEData dangerouslySetInnerHTML={{ __html: page.explanation }} />
              </Col>
              <Col md={6}>
                <HowToSteps />
              </Col>
            </Row>
          </CenteredContainer>
        </HowToSection>
      </Base>
    )
  }
}

export default Home
