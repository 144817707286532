import OfferOverview from './pages/OfferOverview'
import OfferDetail from './pages/OfferDetail'
import OfferRedirect from './pages/OfferRedirect'
import VendorDetail from './pages/VendorDetail'
import NotFound from './pages/NotFound'
import Home from './pages/Home'
import DynamicPage from './pages/DynamicPage'
import VendorOverview from './pages/VendorOverview'
import ContactPage from './pages/ContactPage'

export const getOfferIndexRoute = (): string => '/aanbiedingen'
export const getOfferDetailRoute = (slug: string): string => `/aanbiedingen${slug}`
export const getOfferRedirectRoute = (slug: string): string => `/aanbiedingen${slug}/redirect`
export const getVendorIndexRoute = (): string => '/parken'
export const getVendorDetailRoute = (slug: string): string => `/parken${slug}`

export default [
  {
    path: '/',
    exact: true,
    component: Home
  },
  {
    path: '/aanbiedingen',
    exact: true,
    component: OfferOverview
  },
  {
    path: '/aanbiedingen/:slug',
    exact: true,
    component: OfferDetail
  },
  {
    path: '/aanbiedingen/:slug/redirect',
    exact: true,
    component: OfferRedirect
  },
  {
    path: '/parken',
    exact: true,
    component: VendorOverview
  },
  {
    path: '/parken/:slug',
    component: VendorDetail
  },
  {
    path: '/contact',
    component: ContactPage
  },
  {
    // When the page data is not found, it returns a 404 statusCode which 
    // automatically triggers the NotFound route below
    path: '/*',
    component: DynamicPage,
  },
  {
    component: NotFound,
  },
]
