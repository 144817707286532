import React from 'react'
import styled from 'styled-components'
import Row from '../layout/Row'
import Col from '../layout/Col'

interface ContactInformationProps {
  data: string
}

const ContactInformationSection = styled.section`
  margin-top: 4rem;
`

const ContactInformation: React.FC<ContactInformationProps> = ({ data }: ContactInformationProps) => (
  <Row>
    <Col col={12}>
      <ContactInformationSection dangerouslySetInnerHTML={{ __html: data }} />
    </Col>
  </Row>
)

export default ContactInformation;
