import React from 'react'
import styled from 'styled-components'
import H3 from '../typography/H3'
import Row from '../layout/Row'
import Col from '../layout/Col'

interface VendorDescriptionProps {
  name: string
  description: string
}

const AboutVendor = styled.div`
  font-size: 1.2rem;
`

const VendorDescription: React.FC<VendorDescriptionProps> = ({ name, description }) => (
  <Row>
    <Col col={12}>
      <H3>Over {name}</H3>
      <AboutVendor dangerouslySetInnerHTML={{__html: description}} />
    </Col>
  </Row>
)

export default VendorDescription
