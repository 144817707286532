export default {
  // Buttons
  // 'button.button_key': 'Button label',

  // Errors
  // 'error.error_key': 'Error tekst',

  // Form labels
  'label.email': 'E-mailadres',

  // Titles
  'title.404': 'Pagina niet gevonden',
  'title.404_subtitle': 'Deze pagina bestaat niet (meer), of er zit een typfout in het adres. Controleer of het adres juist is gespeld',
  'title.libema_footer': 'De mooiste tijd beleef je bij {account}, onderdeel van',
  'title.offers': 'Aanbiedingen',
  'title.how_it_works': 'Hoe werkt het?',
  'title.participating_parks': 'Deelnemende parken',
  'title.footer_cookie_link': 'Cookieverklaring',
  'title.footer_privacy_link': 'Privacy statement',
  'title.footer_actievoorwarden_link': 'Voorwaarden en Disclaimer',
  'title.footer_faq_link': 'Veelgestelde vragen',
  'title.footer_contact_link': 'Contact',
  'title.footer_secure_payment_heading': 'Veilig online betalen:',
  'title.footer_social_heading': 'Vind ons ook op:',
  'title.footer_instagram_logo': 'Instagram',
  'title.footer_facebook_logo': 'Facebook',

  // Form validators
  'validator.email_invalid': 'Ongeldig e-mailadres',

  // Warnings
  'warning.old_browser': 'Je maakt gebruik van een <strong>verouderde</strong> browser. <a href="https://browsehappy.com/">Upgrade je browser</a> om het gebruik en de veiligheid te verbeteren.',
  'text.contact_page_questions': 'Heb je vragen of opmerkingen over één van de acties? Bekijk onze veel gestelde vragen of verstuur je vraag via onderstaand formulier.',
  'text.contact_page_contact_details': 'Libéma via <strong>073-5282200</strong> of via <a href="mailto: publicrelations@libema.nl">publicrelations@libema.nl</a>'
}
