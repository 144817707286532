import styled from 'styled-components'

export default styled.div<{ backgroundImage: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 400px;
  background-image: ${({ backgroundImage }) => `url('${backgroundImage}')`};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
`
