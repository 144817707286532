import React from 'react'
import styled from 'styled-components'
import Row from '../layout/Row'
import Col from '../layout/Col'
import H3 from '../typography/H3'
import OfferCard from '../offers/OfferCard'
import { Offer } from '../../models'

const VendorOffersSection = styled.section`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

interface VendorOffersProps {
  offers: Offer[]
}

const VendorOffers: React.FC<VendorOffersProps> = ({ offers }) => (
  <VendorOffersSection>
    <Row>
      <Col>
        <H3>Aanbiedingen</H3>
      </Col>
    </Row>
    <Row>
      {offers.slice(0, 2).map((offer) => (
        <Col md={6} key={offer.id}>
          <OfferCard offer={offer} />
        </Col>
      ))}
    </Row>
  </VendorOffersSection>
)

export default VendorOffers
