import styled from 'styled-components'
import Loader from './Loader'

export default styled(Loader)`
  margin-right: ${({ theme }) => theme.margin.sm};
  width: 1em;
  height: 1em;
  top: .125em;
  position: relative;
`
