import React from 'react'
import styled from 'styled-components'
import H1 from '../components/typography/H1'
import { getOffers } from '../api/offerApi'
import { Offer } from '../models'
import Base from './Base'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import MainContainer from '../components/layout/MainContainer'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import CardBody from '../components/cards/CardBody'
import OfferCard from '../components/offers/OfferCard'
import Helmet from 'react-helmet'

const IntroText = styled.p`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

interface OfferOverviewProps {
  offers: Offer[]
}

class OfferOverview extends React.Component<OfferOverviewProps> {
  static async getInitialProps() {
    const offerResponse = await getOffers()
    return {
      offers: offerResponse.data.items
    }
  }

  render = () => {
    return (
      <Base isIE={false}>
        <Helmet>
          <title>Alle aanbiedingen op een rij | Dierentuintickets.nl</title>
          <meta name="description" content="Zie alle aanbiedingen op een rij van diverse dierentuinen in Nederland." />
          <meta name="robots" content="index, follow" />
        </Helmet>
        <HeadingSection>
          {this.props.offers.length > 0 && typeof this.props.offers[0].background_image?.jumbotron !== 'undefined' && (
            <HeadingBackground
              backgroundImage={this.props.offers[0].background_image?.jumbotron}
            />
          )}
          <MainContainer>
            <Breadcrumbs
              parts={[
                { label: 'Aanbiedingen', slug: false }
              ]}
            />
            <CardBody>
              <H1>Alle aanbiedingen netjes op een rijtje</H1>
              <IntroText>Voordelig naar een dierentuin met onze tijdelijke aanbiedingen</IntroText>
              <Row>
                {this.props.offers.map((offer) => (
                  <Col md={6} key={offer.id}>
                    <OfferCard offer={offer} />
                  </Col>
                ))}
              </Row>
            </CardBody>
          </MainContainer>
        </HeadingSection>
      </Base>
    )
  }
}

export default OfferOverview
