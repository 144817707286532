import React from 'react'
import styled from 'styled-components'
import { HowToPage } from '../models'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import MainContainer, { CenteredContainer } from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'
import H1 from '../components/typography/H1'
import { Link } from 'react-router-dom'
import RTEData from '../components/typography/RTEData'
import HowToSteps from '../components/howto/HowToSteps'
import { getOfferIndexRoute } from '../routes'
import Helmet from 'react-helmet'
import Center from '../components/layout/Center'
import PrimaryButton from '../components/buttons/PrimaryButton'
import Breadcrumbs from '../components/layout/Breadcrumbs'

const Introduction = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

interface HowToProps {
  page: HowToPage
}

const Section = styled.section`
  margin-top: ${({ theme }) => theme.margin.lg};
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const HowTo: React.FC<HowToProps> = ({ page }) => (
  <>
    <Helmet>
      <title>{page?.meta_title || page.title}</title>
      <meta name="description" content={page.meta_description} />
      <meta name="robots" content={page.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
    </Helmet>
    <HeadingSection>
      <HeadingBackground
        backgroundImage={page.background_image.jumbotron}
      />
      <MainContainer>
        <Breadcrumbs
          parts={[
            { slug: false, label: page.title }
          ]}
        />
        <CardBody>
          <H1>{page.title}</H1>
          <Introduction>
            <RTEData dangerouslySetInnerHTML={{ __html: page.description }} />
          </Introduction>
        </CardBody>
      </MainContainer>
    </HeadingSection>
    <Section>
      <CenteredContainer>
        <Row>
          <Col md={6}>
            <RTEData dangerouslySetInnerHTML={{ __html: page.explanation }} />
          </Col>
          <Col md={6}>
            <HowToSteps />
          </Col>
        </Row>
      </CenteredContainer>
    </Section>
    <Section>
      <Center>
        <PrimaryButton as={Link} to={getOfferIndexRoute()}>
          NAAR DE AANBIEDINGEN
        </PrimaryButton>
      </Center>
    </Section>
  </>
)

export default HowTo
