import React from 'react'
import { Col, Row } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import LocationIcon from '@fortawesome/fontawesome-free/svgs/solid/map-marker-alt.svg'
import H1 from '../typography/H1'
import VendorImage from './VendorImage'
import { ImageSizes } from '../../models'

const LocationContent = styled.div`
  display: flex;
  font-style: italic;
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const LocationPin = styled(LocationIcon)`
  display: inline-block;
  margin: 0 1rem;
  width: 1.25rem;
  fill: ${({ theme }) => theme.color.secondary};
`

interface VendorHeaderProps {
  children?: any
  name: string
  main_image: ImageSizes
  logo: string
  location: string
}

const VendorHeader: React.FC<VendorHeaderProps> = ({ children, name, main_image, logo, location }) => (
  <Row>
    <Col col={12} md={6}>
      <H1>{name}</H1>
      <LocationContent>
        <div dangerouslySetInnerHTML={{ __html: location }} />
        <LocationPin />
      </LocationContent>
      {children}
    </Col>
    <Col col={12} md={6}>
      <VendorImage
        image={main_image}
        logo={logo}
      />
    </Col>
  </Row>
)

export default VendorHeader
