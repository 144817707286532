import React from 'react'
import pageApi from '../api/pageApi'
import NotFound from './NotFound'
import Base from './Base'
import { CtxBase } from '@jaredpalmer/after'
import { RouteComponentProps } from 'react-router-dom'
import { Page, HowToPage } from '../models'
import HowTo from './HowTo'
import DefaultPage from './DefaultPage'
import CookiePage from './CookiePage'

type AsyncProps = CtxBase & RouteComponentProps

interface DynamicPageProps extends AsyncProps {
  isIE: boolean
  page?: Page | HowToPage
}

/**
 * This component renders a specific page based on their type
 */
class DynamicPage extends React.Component<DynamicPageProps> {
  static async getInitialProps({ isIE, match }: DynamicPageProps) {
    try {
      if (match.url === '/error') {
        throw new Error('Testing the error page')
      }

      const pageResponse = await pageApi.getPage(match.url)
      return {
        isIE,
        page: pageResponse.data
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { statusCode: 404 }
      }

      throw error
    }
  }

  render = () => {
    const { page, isIE } = this.props

    if (typeof page === 'undefined') {
      return (
        <NotFound />
      )
    }
  
    switch (page.type) {
      case 'dierentuintickets_default': {
        return (
          <Base isIE={isIE}>
            <DefaultPage page={page as Page} />
          </Base>
        )
      }
      case 'dierentuintickets_how_to': {
        return (
          <Base isIE={isIE}>
            <HowTo page={page as HowToPage} />
          </Base>
        )
      }
      case 'dierentuintickets_cookie_declaration': {
        return (
          <CookiePage page={page as Page} />
        )
      }
      default: {
        return (
          <NotFound />
        )
      }
    }
  }
}

export default DynamicPage
