import styled from 'styled-components'
import PrimaryButton from './PrimaryButton'
import { media } from 'styled-bootstrap-grid'

export default styled(PrimaryButton)`
  width: 100%;
  font-size: 1rem;
  text-transform: uppercase;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 35px;
  border-bottom-right-radius: 35px;
  ${media.tablet`
    width: auto;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  `}
`
