import { AxiosPromise } from 'axios'
import { post } from '.'

interface PostContactFormResponse {
  success: boolean
}

export const postContact = (
  selectedContact: string | null,
  email: string,
  firstname: string,
  comment: string,
  phonenumber?: string
): AxiosPromise<PostContactFormResponse> =>
  post({
    url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/form-posts`,
    data: { selectedContact, email, firstname, comment, phonenumber },
  })
