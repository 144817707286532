import React from 'react'
import HowToStep from './HowToStep'

const HowToSteps: React.FC = () => (
  <ol>
    <HowToStep step={1} icon="sign-in">
      Meld je vrijblijvend aan
    </HowToStep>
    <HowToStep step={2} icon="specials">
      Bekijk de actuele dierentuin aanbiedingen
    </HowToStep>
    <HowToStep step={3} icon="select">
      Kies jouw dierentuin ticket(s) met korting
    </HowToStep>
    <HowToStep step={4} icon="shop">
      Koop je ticket(s) direct online bij de gekozen dierentuin
    </HowToStep>
    <HowToStep step={5} icon="eticket">
      Je ontvangt jouw e-ticket
    </HowToStep>
  </ol>
)

export default HowToSteps
