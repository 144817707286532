import React from 'react'
import { Carousel as BaseCarousel } from 'react-responsive-carousel'
import styled from 'styled-components'
import { ImageSizes } from '../../models'

const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 50px;
  width: 40px;
  background-color: ${({ theme }) => theme.color.neutralMedium};
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    content: '';
    display: inline-block;
  }
`

const PrevButton = styled(NavButton)`
  left: 0;
  border-radius: 0 50% 50% 0;
  span {
    margin-right: 5px;
    border-right: 15px solid ${({ theme }) => theme.color.neutralLight};
  }
`

const NextButton = styled(NavButton)`
  right: 0;
  border-radius: 50% 0 0 50%;
  span {
    margin-left: 5px;
    border-left: 15px solid ${({ theme }) => theme.color.neutralLight};
  }
`

interface CarouselProps {
  gallery: ImageSizes[]
}

const Carousel = ({ gallery }: CarouselProps) => (
  <BaseCarousel
    dynamicHeight={true}
    renderArrowPrev={(clickHandler, hasPrev) => {
      if (!hasPrev) {
        return null
      }

      return (<PrevButton onClick={clickHandler}><span /></PrevButton>)
    }}
    renderArrowNext={(clickHandler, hasNext) => {
      if (!hasNext) {
        return null
      }

      return (<NextButton onClick={clickHandler}><span /></NextButton>)
    }}
    showThumbs={false}
    showStatus={false}
  >
    {gallery?.map((imageSizes: ImageSizes, index) => (
      <div key={index}>
        <img src={imageSizes.gallery} />
      </div>
    ))}
  </BaseCarousel>
)

export default Carousel
