import { AxiosPromise } from 'axios'
import { get } from '.'
import { Page } from '../models'

const getPage = <T extends Page>(slug: string): AxiosPromise<T> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/pages/slug${slug}`
})

export default {
  getPage
}
