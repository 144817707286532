import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import BaseContainer from './Container'
import Row from './Row'
import Col from './Col'

const Container = styled(BaseContainer)`
  ${media.smaller`
    padding: 0;
    overflow-x: hidden;
  `}
`

export const CenteredContainer: React.FC = ({ children }) => (
  <BaseContainer>
    <Row>
      <Col col={12} md={10} mdOffset={1}>
        {children}
      </Col>
    </Row>
  </BaseContainer>
)

const MainContainer: React.FC = ({ children }) => (
  <Container>
    <Row>
      <Col col={12} md={10} mdOffset={1}>
        {children}
      </Col>
    </Row>
  </Container>
)

export default MainContainer
