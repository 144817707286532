import styled, { css } from 'styled-components'

interface H2Props {
  centered?: boolean;
}

export const h2Styles = css`
  color: ${({ theme }) => theme.color.secondary};
  font-family: ${({ theme }) => theme.font.title};
  font-weight: normal;
  font-size: 1.7rem;
  margin-bottom: 1rem;
`

export default styled.h2<H2Props>`
  ${h2Styles}
  text-align: ${({ centered }) => centered ? 'center' : 'inherit'};
`
