import { AxiosPromise } from 'axios'
import { get } from '.'
import { Offer } from '../models'

export const getOffer = (slug: string): AxiosPromise<Offer> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/offers/slug/${slug}`,
})

export const getOffers = (): AxiosPromise<{ items: Offer[] }> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/offers?active=true&sort=priority&direction=desc`,
})
