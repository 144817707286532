import React from 'react'
import Helmet from 'react-helmet'
import { Page } from '../models'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import MainContainer from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'
import H1 from '../components/typography/H1'
import RTEData from '../components/typography/RTEData'
import Breadcrumbs from '../components/layout/Breadcrumbs'

interface DefaultPageProps {
  page: Page
}

const HowTo: React.FC<DefaultPageProps> = ({ page }) => (
  <>
    <Helmet>
      <title>{page?.meta_title || page.title}</title>
      <meta name="description" content={page.meta_description} />
      <meta name="robots" content={page.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
    </Helmet>
    <HeadingSection>
      <HeadingBackground
        backgroundImage={page.background_image?.jumbotron}
      />
      <MainContainer>
        <Breadcrumbs
          parts={[
            { slug: false, label: page.title }
          ]}
        />
        <CardBody>
          <H1>{page.title}</H1>
          <RTEData dangerouslySetInnerHTML={{ __html: page.description }} />
        </CardBody>
      </MainContainer>
    </HeadingSection>
  </>
)

export default HowTo
