import React from 'react'
import styled from 'styled-components'
import DiscountIcon from '../../../public/img/icons/discount.svg'
import TimeIcon from '../../../public/img/icons/time.svg'
import { media } from 'styled-bootstrap-grid'

const OfferUspList = styled.ul`
  display: flex;
  align-items: stretch; /* Default */
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  flex-direction: column;

  ${media.tablet`
    flex-direction: row;
  `}

  li {
    display: block;
    flex: 0 1 auto;
    list-style-type: none;
    margin-right: ${({ theme }) => theme.margin.lg};

    svg {
      width: 1em;
      height: 1em;
      top: .125em;
      position: relative;
      margin-right: ${({ theme }) => theme.margin.md};
      fill: ${({ theme }) => theme.color.greenNeutral};
    }
  }
`

const OfferUsps: React.FC<{ discountLabel: string }> = ({ discountLabel }) => (
  <OfferUspList>
    <li>
      <TimeIcon />
      <strong>Beperkte tijd</strong> beschikbaar
    </li>
    <li>
      <DiscountIcon />
      {discountLabel}
    </li>
  </OfferUspList>
)

export default OfferUsps
