import React from 'react'
import styled from 'styled-components'
import LocationIcon from '@fortawesome/fontawesome-free/svgs/solid/map-marker-alt.svg'

const Pin = styled(LocationIcon)`
  display: inline-block;
  width: 1em;
  height: 1em;
  top: .125em;
  position: relative;
  fill: ${({ theme }) => theme.color.secondary};
`

const VendorLocationWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  font-style: italic;
  color: ${({ theme }) => theme.textColor.muted};
`

const VendorLocation: React.FC<{ location: string }> = ({ location }) => (
  <VendorLocationWrapper>
    <div dangerouslySetInnerHTML={{ __html: location }} />
    &nbsp;
    <Pin />
  </VendorLocationWrapper>
)

export default VendorLocation
