import React from 'react'
import styled from 'styled-components'

interface InputProps {
  error?: boolean;
  onChange?: (e: React.ChangeEvent<any>) => void;
}

export default styled.input<InputProps>`
  display: block;
  width: 100%;
  padding: 0.9rem .75rem;
  border: 1px solid ${({ theme, error }) => error ? theme.color.error : theme.color.dark};
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`
