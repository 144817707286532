import styled, { css } from 'styled-components'

export const h1Styles = css`
  color: ${({ theme }) => theme.color.secondary};
  font-family: ${({ theme }) => theme.font.title};
  font-weight: normal;
  font-size: 2rem;
  margin-bottom: 1rem;
`

export default styled.h1`
  ${h1Styles}
`
