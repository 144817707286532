import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'
import Base from './Base'
import HeadingSection from '../components/layout/HeadingSection'
import MainContainer from '../components/layout/MainContainer'
import CardBody from '../components/cards/CardBody'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import { Page } from '../models'
import HeadingBackground from '../components/layout/HeadingBackground'
import H1 from '../components/typography/H1'
import RTEData from '../components/typography/RTEData'

interface CookiePageProps {
  page: Page
}

const MainContent = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const CookiePage: React.FC<CookiePageProps> = ({ page }) => {
  const cookiebotRef = React.useRef(null)
  
  React.useEffect(
    () => {
      const script = document.createElement('script')

      script.id = 'CookieDeclaration'
      script.src = 'https://consent.cookiebot.com/751d5dce-8527-4f44-8231-6e63ee574965/cd.js'
      script.async = true

      // @ts-ignore
      cookiebotRef.current.appendChild(script)

      return () => {
        // @ts-ignore
        cookiebotRef.current.removeChild(script)
      }
    },
    []
  )

  return (
    <Base isIE={false}>
      <Helmet>
        <title>{page?.meta_title || page.title}</title>
        <meta name="description" content={page.meta_description} />
        <meta name="robots" content={page.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
      </Helmet>
      <HeadingSection>
        <HeadingBackground
          backgroundImage={page.background_image?.jumbotron}
        />
        <MainContainer>
          <Breadcrumbs
            parts={[
              { slug: false, label: page.title }
            ]}
          />
          <CardBody>
            <H1>{page.title}</H1>
            <MainContent>
              <RTEData dangerouslySetInnerHTML={{ __html: page.description }} />
            </MainContent>
            <div ref={cookiebotRef} />
          </CardBody>
        </MainContainer>
      </HeadingSection>
    </Base>
  )
}

export default CookiePage
