import React from 'react'
import { FieldProps } from 'formik'
import Input from '../Input'

interface TextFieldProps extends FieldProps {
  placeholder?: string
  type: string
}

const TextField: React.FC<TextFieldProps> = ({ field, placeholder, type, form: { errors, touched } }) => (
  <Input
    name={field.name}
    onChange={field.onChange}
    placeholder={placeholder}
    type={type || 'text'}
    value={field.value}
    error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
  />
)

export default TextField
