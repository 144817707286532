import styled, { keyframes } from 'styled-components'
import LoaderIcon from '../../../public/img/icons/loader.svg'

const rotate = keyframes`
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`

export default styled(LoaderIcon)`
  width: 1rem;
  -webkit-animation: ${rotate} 3s linear infinite;
  animation: ${rotate} 3s linear infinite;
`
