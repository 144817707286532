import React from 'react'
import styled from 'styled-components'
import RootProvider from '../components/providers/RootProvider'
import TranslateHtml from '../components/typography/TranslateHtml'
import Header from '../components/layout/Header'
import Footer from '../components/layout/Footer'
import SubscriptionModal from '../components/subscription/SubscriptionModal'
import EnvironmentAlert from '../components/alerts/EnvironmentAlert'

import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'moment/locale/nl'

interface BaseProps {
  isIE: boolean
}

const Main = styled.main`
  flex: 1;
`

const Base: React.FC<BaseProps> = ({ children, isIE }) => (
  <RootProvider locale="nl">
    {isIE && (
      <p className="oldbrowser">
        <TranslateHtml id="warning.old_browser" />
      </p>
    )}
    {process.env.RAZZLE_STAGE !== 'production' && (
      <EnvironmentAlert>You're looking at a test environment</EnvironmentAlert>
    )}
    <Header />
    <Main>
      {children}
    </Main>
    <Footer />
    <SubscriptionModal />
  </RootProvider>
)

export default Base
