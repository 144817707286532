import React from 'react';
import ReactSelect, { Props as ReactSelectProps, StylesConfig } from 'react-select';
import ReactAsyncSelect, { Props as ReactAsyncSelectProps } from 'react-select/async';
import theme from '../../theme'

export interface SelectOption {
  value?: string | number;
  label: string;
}

interface SelectProps extends ReactSelectProps<SelectOption> {
  error?: boolean;
  touched?: boolean;
  backgroundColor?: string;
}

interface AsyncSelectProps extends ReactAsyncSelectProps<SelectOption> {
  error?: boolean;
  touched?: boolean;
  backgroundColor?: string;
}

const styles = ({ backgroundColor, error, touched }: SelectProps | AsyncSelectProps): StylesConfig => ({
  control: (style, state) => ({
    ...style,
    // border: touched && error ? `2px solid ${theme.borderColor.error}` : 'none',
    border: `1px solid ${theme.color.neutralDark}`,
    backgroundColor: '#fff',
    height: '48px',
    minHeight: '48px',
    // fontSize: '1rem',
  }),
  placeholder: (style) => ({
    ...style,
    marginLeft: '7px',
    color: theme.color.neutralDark,
    // fontSize: theme.font.size.m,
  }),
  singleValue: (style, state) => ({
    ...style,
    color: theme.color.dark,
  }),
});

export const AsyncSelect: React.FC<AsyncSelectProps> = (props) => (
  <ReactAsyncSelect
    {...props}
    styles={props.styles || styles(props)}
  />
);

export const Select: React.FC<SelectProps> = (props) => (
  <ReactSelect
    noOptionsMessage={() => 'No options'}
    {...props}
    styles={props.styles || styles(props)}
    theme={(selectTheme) => ({
      ...selectTheme,
      colors: {
        ...selectTheme.colors,
        primary: theme.color.neutralDark,
        neutral50: theme.color.neutralMedium,
      }
    })}
  />
);

export default Select;
