import styled from 'styled-components'
import { h1Styles } from './H1'
import { h2Styles } from './H2'
import { h3Styles } from './H3'

/**
 * This is a simple wrapper that styles data provided by a rich text editor.
 */
export default styled.div`
  text-align: left;

  ul {
    list-style: disc;
    padding-left: 20px;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  .ql-align-center {
    text-align: center;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-left {
    text-align: left;
  }

  h1 {
    ${h1Styles}
  }
  h2 {
    ${h2Styles}
  }
  h3 {
    ${h3Styles}
  }
`
