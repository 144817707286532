import React from 'react'
import styled from 'styled-components'

type Size = 'sm' | 'lg'

const Large = styled.span<{ size: Size }>`
  font-size: ${({ size }) => size === 'sm' ? '1rem' : '2rem'};
  font-weight: ${({ size }) => size === 'sm' ? 'normal' : 'bold'};
  text-decoration: ${({ size }) => size === 'sm' ? 'line-through' : 'none'};
`

const Small = styled.span<{ size: Size }>`
  font-size: ${({ size }) => size === 'sm' ? '0.8rem' : '1.4rem'};
  font-weight: ${({ size }) => size === 'sm' ? 'normal' : 'bold'};
  vertical-align: ${({ size }) => size === 'sm' ? '3px' : 'top'};
  text-decoration: ${({ size }) => size === 'sm' ? 'line-through' : 'none'};
`

interface SplitValue {
  round: string
  decimal: string
}

const splitValue = (value: number = 0): SplitValue => {
  const stringValue = value.toFixed(2)
  const split = stringValue.split('.')
  return {
    round: `${split[0]},`,
    decimal: split[1]
  }
}

interface PriceLabelProps {
  prefix?: string
  size: Size
  value: number
}

const PriceLabel: React.FC<PriceLabelProps> = ({ prefix, size, value }) => {
  const price = splitValue(value)
  return (
    <>
      {prefix ? (<Large size={size}>{prefix}</Large>) : null}
      {value > 0 && (
        <>
          <Large size={size}>{price.round}</Large>
          <Small size={size}>{price.decimal}</Small>
        </>
      )}
    </>
  )
}

export default PriceLabel
