import React from 'react'
import styled from 'styled-components'
import Row from '../../components/layout/Row'
import Col from '../../components/layout/Col'
import { Offer } from '../../models'
import CardBody from '../../components/cards/CardBody'
import CardHeader from '../../components/cards/CardHeader'
import H3 from '../../components/typography/H3'
import PriceLabel from '../../components/offers/PriceLabel'
import DiscountLabel from '../../components/offers/DiscountLabel'
import OfferUsps from '../../components/offers/OfferUsps'
import CheckoutButton from '../../components/buttons/CheckoutButton'
import Small from '../typography/Small'
import { media } from 'styled-bootstrap-grid'
import Highlighted from '../typography/Highlighted'
import { getOfferDetailRoute, getOfferIndexRoute, getOfferRedirectRoute } from '../../routes'
import moment from 'moment'
import PrimaryButton from '../buttons/PrimaryButton'
import { Link } from 'react-router-dom'
import Center from '../layout/Center'

interface OfferTicketProps {
  offer: Offer
}

const TicketName = styled(H3)`
  margin-bottom: 0;
`

const TopRow = styled.div`
  height: auto;
`

const BottomRow = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const TicketColumn = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const VendorLogo = styled.img`
  max-width: 100%;
  margin-top: ${({ theme }) => theme.margin.md};
  margin-bottom: ${({ theme }) => theme.margin.md};
  ${media.tablet`
    margin-top: 0;
    margin-bottom: 0;
  `}
`

const PriceLabels = styled.div`
  text-align: right;
  ${media.tablet`
    text-align: left;
  `}
`

const SmallLink = styled(Link)`
  font-size: 0.8rem;
  display: block;
  text-align: center;
`

const OfferTicket: React.FC<OfferTicketProps> = ({ offer }) => {
  const isValid = moment(offer.valid_to) > moment()
  const ref = getOfferDetailRoute(offer.slug).substring(1)
  const url = `${offer.product_link}?referral=${ref}`

  return (
    <>
      <CardHeader>
        <OfferUsps discountLabel={offer.product_discount} />
      </CardHeader>
      <CardBody>
        <DiscountLabel>
          {offer.product_discount}
        </DiscountLabel>
        <Row>
          <TicketColumn md={5}>
            <TopRow>
              <TicketName>{offer.product_name}</TicketName>
              <Small>{offer.product_description}</Small>
            </TopRow>
            <BottomRow>
              Tickets geldig<br />
              <Highlighted>{offer?.product_validity}</Highlighted>
            </BottomRow>
          </TicketColumn>
          <TicketColumn md={3}>
            <TopRow>
              <PriceLabels>
                <PriceLabel size="sm" value={offer?.product_from_price || 0} prefix="€" />
                &nbsp;&nbsp;&nbsp;
                <PriceLabel size="lg" value={offer?.product_price} prefix="€" />
              </PriceLabels>
            </TopRow>
            <BottomRow>
              <VendorLogo src={offer?.vendor?.logo} />
            </BottomRow>
          </TicketColumn>
          <TicketColumn md={4}>
            <div />
            {isValid && (
              <CheckoutButton to={url} />
            )}
            {!isValid && (
              <Center>
                <PrimaryButton disabled={true}>
                  Actie verlopen
                </PrimaryButton>
                <SmallLink to={getOfferIndexRoute()}>Bekijk overige aanbiedingen</SmallLink>
              </Center>
            )}
          </TicketColumn>
        </Row>
      </CardBody>
    </>
  )
}

export default OfferTicket
