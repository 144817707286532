import React from 'react'
import { media } from 'styled-bootstrap-grid'
import styled from 'styled-components'
import { RouteComponentProps } from 'react-router-dom'
import MainContainer from '../components/layout/MainContainer'
import Breadcrumbs from '../components/layout/Breadcrumbs'
import Row from '../components/layout/Row'
import Col from '../components/layout/Col'
import H2 from '../components/typography/H2'
import Carousel from '../components/carousel/Carousel'
import VendorHeader from '../components/vendors/VendorHeader'
import VendorOffers from '../components/vendors/VendorOffers'
import VendorDescription from '../components/vendors/VendorDescription'
import ContactInformation from '../components/vendors/ContactInformation'
import { Vendor } from '../models'
import NotFound from './NotFound'
import CardBody from '../components/cards/CardBody'
import { getVendor } from '../api/vendorApi'
import Base from './Base'
import HeadingBackground from '../components/layout/HeadingBackground'
import Helmet from 'react-helmet'

const HeadingSection = styled.section`
  position: relative;
  width: 100%;
  background-color: #fff;
  ${({ theme }) => media.tablet`
    background-color: ${theme.color.neutralLight};
    padding-top: 4rem;
  `}
`

const BottomPartWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.lg};
`

const WhiteSection = styled.section`
  background-color: #fff;
`

interface VendorDetailProps extends RouteComponentProps<{ slug: string }> {
  vendor: Vendor
}

class VendorDetail extends React.Component<VendorDetailProps> {
  static async getInitialProps({ match }: VendorDetailProps) {
    try {
      const vendorResponse = await getVendor(match.params.slug)
      return {
        vendor: vendorResponse.data
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return { statusCode: 404 }
      }

      throw error
    }
  }

  render = () => {
    const { vendor } = this.props
    if (typeof vendor === 'undefined') {
      return <NotFound />
    }
  
    return (
      <Base isIE={false}>
        <Helmet>
          <title>{vendor?.meta_title || vendor.name}</title>
          <meta name="description" content={vendor.meta_description} />
          <meta name="robots" content={vendor.indexable === false ? 'noindex, nofollow' : 'index, follow'} />
        </Helmet>
        <HeadingSection>
          <HeadingBackground
            backgroundImage={vendor.background_image.jumbotron}
          />
          <MainContainer>
            <Breadcrumbs
              parts={[
                { slug: '/parken', label: 'Parken' },
                { slug: false, label: vendor.name }
              ]}
            />
            <CardBody>
              <VendorHeader
                name={vendor?.name}
                main_image={vendor.main_image}
                logo={vendor.logo_secondary}
                location={vendor.location}
              />
              <VendorOffers
                offers={vendor.offers || []}
              />
              <VendorDescription
                name={vendor?.name}
                description={vendor?.description}
              />
            </CardBody>
          </MainContainer>
        </HeadingSection>
        <WhiteSection>
          <MainContainer>
            <BottomPartWrapper>
              <Row>
                <Col col={12}>
                  <H2 centered={true}>Alvast een voorproefje?</H2>
                </Col>
              </Row>
              <Row>
                <Col col={12}>
                  <Carousel gallery={vendor?.gallery} />
                </Col>
              </Row>
              <ContactInformation
                data={vendor?.contact_information}
              />
            </BottomPartWrapper>
          </MainContainer>
        </WhiteSection>
      </Base>
    )
  }
}

export default VendorDetail
