import styled from 'styled-components'

interface ParagraphProps {
  inlineBlock?: boolean;
  italic: boolean;
  [key: string]: any;
}

export default styled.p<ParagraphProps>`
  display: ${({inlineBlock}) => inlineBlock ? 'inline-block' : 'block'};
  font-style: ${({italic}) => italic ? 'italic' : 'normal'};
`
