import styled from 'styled-components'

export default styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${({ theme }) => theme.color.tertiary};
  border-bottom-left-radius: 30px;
  padding: 10px 20px 10px 30px;
  font-weight: bold;
`
