import styled from 'styled-components'

export interface ButtonProps {
  fullWidth?: boolean
}

export default styled.button<ButtonProps>`
  display: inline-block;
  width: ${({ fullWidth }) => fullWidth === true ? '100%' : 'auto'};
  color: ${({ theme }) => theme.textColor.default};
  font-weight: bold;
  font-size: 1.2rem;
  padding: ${({ theme }) => `${theme.padding.md} ${theme.padding.lg}`};
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &:visited {
    color: ${({ theme }) => theme.textColor.default};
  }
`
