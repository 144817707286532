import React from 'react'
import styled from 'styled-components'

interface TextAreaProps {
  error?: boolean
  onChange?: (e: React.ChangeEvent<any>) => void
}

export default styled.textarea<TextAreaProps>`
  display: block;
  width: 100%;
  padding: 0.9rem 0.75rem;
  border: 1px solid ${({ theme, error }) => (error ? theme.color.error : theme.color.dark)};
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
`
