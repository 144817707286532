import React from 'react'
import styled from 'styled-components'
import Modal from '../modals/Modal'
import H1 from '../typography/H1'
import SubscriptionForm from './SubscriptionForm'
import Small from '../typography/Small'
import Row from '../layout/Row'
import Col from '../layout/Col'
import subscriptionUtils from '../../utils/subscriptionUtils'

const SubscriptionInfo = styled.div`
  text-align: center;
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const CloseModalText = styled(Small)`
  display: block;
  text-align: center;
  margin-top: ${({ theme }) => theme.margin.lg};
  text-decoration: underline;
  cursor: pointer;
`

const SubscriptionModal: React.FC = () => {
  const [isVisible, setVisible] = React.useState<boolean>(false)

  const closeModal = () => setVisible(false)
  const ignore = () => {
    subscriptionUtils.setIgnored()
    closeModal()
  }

  React.useEffect(
    () => {
      if (!window.location.href.includes(`/nieuwsbrief/afmelden`) && !subscriptionUtils.hasMadeAChoice()) {
        setTimeout(() => setVisible(true), 5000)
      }
    },
    []
  )

  return (
    <Modal isVisible={isVisible} onClose={ignore}>
      <SubscriptionInfo>
        <H1>Tot 35% korting op dierentuin tickets</H1>
        <strong>Wil jij geen enkele aanbieding missen?</strong>
        <p>
          Onze aanbiedingen zijn slechts beperkt geldig.<br />
          Dankzij onze e-mail hoef je niks te missen! Meld je aan.
        </p>
      </SubscriptionInfo>
      <Row>
        <Col mdOffset={1} md={10}>
          <SubscriptionForm onSuccess={closeModal} buttonLabel="DOORGAAN" />
        </Col>
      </Row>
      <CloseModalText onClick={ignore}>
        Ik ontvang liever geen email met dierentuintickets kortingen in mijn mailbox
      </CloseModalText>
    </Modal>
  )
}

export default SubscriptionModal