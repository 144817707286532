import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import H3 from '../typography/H3'
import { Offer } from '../../models'
import VendorImage from '../vendors/VendorImage'
import VendorLocation from '../vendors/VendorLocation'
import OfferTitle from './OfferTitle'
import OutlineButton from '../buttons/OutlineButton'
import { getOfferDetailRoute } from '../../routes'

interface OfferCardProps {
  offer: Offer
}
 
const SpaceBelow = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const OfferCardWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const OfferCard: React.FC<OfferCardProps> = ({ offer }) => (
  <OfferCardWrapper>
    {typeof offer.vendor !== 'undefined' && (
      <H3>{offer?.vendor?.name}</H3>
    )}
    <SpaceBelow>
      <VendorImage
        image={offer.main_image}
        label={offer.product_discount}
      />
    </SpaceBelow>
    <SpaceBelow>
      <OfferTitle name={offer.name} validTo={offer.valid_to} />
      {typeof offer.vendor !== 'undefined' && (
        <VendorLocation location={offer.vendor?.location} />
      )}
    </SpaceBelow>
    <OutlineButton as={Link} to={getOfferDetailRoute(offer.slug)}>
      Details
    </OutlineButton>
  </OfferCardWrapper>
)

export default OfferCard
