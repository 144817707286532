import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import H3 from '../typography/H3'
import { Vendor } from '../../models'
import VendorImage from '../vendors/VendorImage'
import VendorLocation from '../vendors/VendorLocation'
import OutlineButton from '../buttons/OutlineButton'
import { getVendorDetailRoute } from '../../routes'

interface VendorCardProps {
  vendor: Vendor
}
 
const SpaceBelow = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
`

const VendorCardWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.lg};
`

const VendorCard: React.FC<VendorCardProps> = ({ vendor }) => (
  <VendorCardWrapper>
    <H3>{vendor?.name}</H3>
    <SpaceBelow>
      <VendorImage
        image={vendor.main_image}
        logo={vendor.logo_secondary}
      />
    </SpaceBelow>
    <SpaceBelow>
      <strong>{vendor.short_description || vendor.meta_description}</strong>
      <VendorLocation location={vendor?.location} />
    </SpaceBelow>
    <OutlineButton as={Link} to={getVendorDetailRoute(vendor.slug)}>
      Bekijk aanbiedingen
    </OutlineButton>
  </VendorCardWrapper>
)

export default VendorCard
