import React from 'react'
import { FieldProps } from 'formik'
import TextArea from '../TextArea'

interface TextAreaFieldProps extends FieldProps {
  placeholder?: string
  type: string
  rows: number
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({ field, rows, placeholder, form: { errors, touched } }) => (
  <TextArea
    name={field.name}
    onBlur={field.onBlur}
    onChange={field.onChange}
    placeholder={placeholder}
    rows={rows}
    value={field.value}
    error={typeof touched[field.name] !== 'undefined' && typeof errors[field.name] !== 'undefined'}
  />
)

export default TextAreaField
