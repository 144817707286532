import localStorageUtils from './localStorageUtils'

export const SUBSCRIPTION_STATE = 'subscription_state'
export const SUBSCRIPTION_STATUS_SUBSCRIBED = '1'
export const SUBSCRIPTION_STATUS_IGNORED = '0'

// Store subscribed users for a year
const setSubscribed = () => localStorageUtils.set(SUBSCRIPTION_STATE, SUBSCRIPTION_STATUS_SUBSCRIBED, 31536000)
// Store ignoring users for only a day
const setIgnored = () => localStorageUtils.set(SUBSCRIPTION_STATE, SUBSCRIPTION_STATUS_IGNORED, 86400)

const isSubscribed = (): boolean => typeof window !== 'undefined' &&
  localStorageUtils.get(SUBSCRIPTION_STATE) === SUBSCRIPTION_STATUS_SUBSCRIBED
const hasMadeAChoice = (): boolean => typeof window !== 'undefined' &&
  localStorageUtils.get(SUBSCRIPTION_STATE) !== null

export default {
  hasMadeAChoice,
  isSubscribed,
  setIgnored,
  setSubscribed
}
