import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const newCancellationToken = () => axios.CancelToken.source();
export const isCancellationError = (err: Error) => axios.isCancel(err);

const getHeaders = (config: AxiosRequestConfig) => ({
  // Authorization: `Bearer ${getToken()}`,
  'Content-Type': 'application/json',
  ...config.headers,
});

export const api = axios.create({
  // baseURL: process.env.RAZZLE_API_BASE_URL,
  // timeout: 5000
});

// api.interceptors.response.use(
//   (response: AxiosResponse) => response,
//   (error: AxiosError) => {
//     return Promise.reject(error);
//   }
// );

export const get = (config: AxiosRequestConfig) => api({
  method: 'GET',
  ...config,
  headers: getHeaders(config)
});

export const put = (config: AxiosRequestConfig) => api({
  method: 'PUT',
  ...config,
  headers: getHeaders(config)
});

export const post = (config: AxiosRequestConfig) => api({
  method: 'POST',
  ...config,
  headers: getHeaders(config)
});
