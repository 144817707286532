import { AxiosPromise } from 'axios'
import { get } from '.'
import { Vendor } from '../models'

export const getVendor = (slug: string): AxiosPromise<Vendor> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/vendors/slug/${slug}`,
})

export const getVendors = (): AxiosPromise<{ items: Vendor[] }> => get({
  url: `${process.env.RAZZLE_API_URL}/accounts/${process.env.RAZZLE_ACCOUNT_ID}/vendors?active=true`,
})

export default {
  getVendor,
  getVendors
}
