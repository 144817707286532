import React from 'react'
import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { RouteComponentProps } from 'react-router-dom'
import { CenteredContainer } from '../components/layout/MainContainer'
import { Offer } from '../models'
import NotFound from './NotFound'
import HeadingSection from '../components/layout/HeadingSection'
import HeadingBackground from '../components/layout/HeadingBackground'
import BaseCardBody from '../components/cards/CardBody'
import { getOffer } from '../api/offerApi'
import Base from './Base'
import Highlighted from '../components/typography/Highlighted'
import { getOfferDetailRoute } from '../routes'
import PrimaryButton from '../components/buttons/PrimaryButton'

interface OfferRedirectProps extends RouteComponentProps<{ slug: string }> {
  offer: Offer
}

const CardBody = styled(BaseCardBody)`
  border-radius: ${({ theme }) => theme.borderRadius.default};
  padding: ${({ theme }) => theme.margin.lg};
  font-size: 1.5rem;
  text-align: center;
  ${({ theme }) => media.tablet`
    padding: 120px ${theme.margin.xl};
  `}
`

class OfferRedirect extends React.Component<OfferRedirectProps> {
  static async getInitialProps({ match }: OfferRedirectProps) {
    const offerResponse = await getOffer(match.params.slug)
    return {
      offer: offerResponse.data
    }
  }

  render = () => {
    const { offer } = this.props

    if (typeof offer === 'undefined') {
      return <NotFound />
    }

    const ref = getOfferDetailRoute(this.props.offer.slug).substring(1)
    const url = encodeURI(`${this.props.offer.product_link}?referral=${ref}`)
  
    return (
      <Base isIE={false}>
        <HeadingSection>
          {typeof offer.background_image?.jumbotron !== 'undefined' && (
            <HeadingBackground
              backgroundImage={offer.background_image?.jumbotron}
            />
          )}

          <CenteredContainer>
            <CardBody>
              <p>
                Je wordt nu doorgestuurd naar de webshop van <Highlighted>{offer.vendor.name}</Highlighted>.
                Hier kun je jouw dierentuin ticket(s) met korting direct online kopen.
              </p>
              <br />
              <PrimaryButton as="a" href={url} title={`Link naar ${offer.vendor.name}`}>
                Stuur mij door
              </PrimaryButton>
            </CardBody>
          </CenteredContainer>
        </HeadingSection>
      </Base>
    )
  }
}

export default OfferRedirect
