import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

interface OfferTitleProps {
  name: string
  validTo: string
}

const OfferName = styled.span`
  color: ${({ theme }) => theme.color.primary};
`

const OfferTitle: React.FC<OfferTitleProps> = ({ name, validTo }) => {
  const validDate = moment(validTo)
  const isValid = validDate > moment()

  let label = 'sluit op'
  if (!isValid) {
    label = 'verlopen op'
  }
  return (
    <strong>
      <OfferName>"{name}"</OfferName>
      , {label} {validDate.format('D MMMM YYYY')} om {validDate.format('HH:mm')}
    </strong>
  )
}

export default OfferTitle
