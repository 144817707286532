export const gridTheme = {
  row: {
    padding: 30
  },
  col: {
    padding: 30
  }
}

export default {
  borderRadius: {
    default: '10px',
    top: '10px 10px 0 0'
  },
  color: {
    dark: '#695603', // Dirty green (e.g. header bg)
    greenLight: '#f2f4e6',
    greenNeutral: '#b4d334',
    neutralDark: '#695603', // Dirty green (e.g. header bg) - Use `dark` instead for now
    neutralMedium: '#898069', // Newsletter background, etc.
    neutralLightMedium: '#c8bda4', // Darker sand =>
    neutralLight: '#f2efe4', // Sand => default background
    primary: '#ff6e00', // Orange => CTA's
    secondary: '#6e8800', // Green => H1, H2, titles, urls, etc.
    tertiary: '#ffd800', // Yellow
    error: '#b31d2b',
    success: '#28a745',
    warning: '#ffc107',
  },
  font: {
    title: '"Fredoka One", "Roboto", Trebuchet MS, -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif',
    titleWeight: 700,
    default: '"Roboto", Trebuchet MS, -apple-system, BlinkMacSystemFont, "San Francisco", "Segoe UI", "Helvetica Neue", sans-serif',
    defaultWeight: 300
  },
  textColor: {
    darkest: '#000000', // H4, strong, etc.
    dark: '#776206', // H3
    default: '#000',
    muted: '#535353',
  },
  margin: {
    sm: '0.7rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem'
  },
  padding: {
    sm: '0.5rem',
    md: '1rem',
    lg: '2rem',
    xl: '4rem'
  }
}
