import styled from 'styled-components'
import { lighten } from 'polished'
import Button from './Button'
import { media } from 'styled-bootstrap-grid'

export default styled(Button)`
  background: ${({ theme }) => `linear-gradient(${lighten(0.15, theme.color.primary)}, ${theme.color.primary})`};
  color: #fff;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  ${media.tablet`
    border-radius: 35px;
  `}

  &:hover {
    background: ${({ theme }) => `linear-gradient(${theme.color.primary}, ${lighten(0.15, theme.color.primary)})`};
    color: #fff;
  }
  &:disabled {
    background: ${({ theme }) => theme.color.neutralLightMedium};
  }
  &:visited {
    color: #fff;
  }
`
