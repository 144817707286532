import React from 'react'
import styled from 'styled-components'
import DeleteIcon from '@fortawesome/fontawesome-free/svgs/solid/times.svg'

const Button = styled.button`
  color: #fff;
  padding: 0;
  display: inline-flex;
  align-self: center;

  &:hover {
    color: ${({ theme }) => theme.color.neutralLight};
  }

  svg {
    width: 1em;
    height: 1em;
    top: .125em;
    position: relative;
  }
`

const DeleteButton = ({ ...props }) => (
  <Button {...props}>
    <DeleteIcon />
  </Button>
)

export default DeleteButton
