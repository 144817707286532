import styled from 'styled-components'
import { media } from 'styled-bootstrap-grid'
import { lighten } from 'polished'
import Button from './Button'

export default styled(Button)`
  background-color: #fff;
  color: ${({ theme }) => theme.color.secondary};
  border: 2px solid ${({ theme }) => theme.color.secondary};
  padding: 0.3rem 1rem;
  text-transform: uppercase;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  font-size: 0.9rem;

  ${media.tablet`
    border-radius: 35px;
  `}

  &:visited {
    color: ${({ theme }) => theme.color.secondary};
  }

  &:hover {
    color: ${({ theme }) => lighten(0.1, theme.color.secondary)};
    border-color: ${({ theme }) => lighten(0.1, theme.color.secondary)};
  }
`
