/**
 * Sets an item to the localStorage with a TTL
 * since localStorage itself does not support a TTL.
 * 
 * @param key The name of the item in localstorage
 * @param data The data to be stored
 * @param ttlInSeconds The time we see the data as valid in seconds
 */
const set = (key: string, data: any, ttlInSeconds: number) => {
  const item = {
    data,
    expiresAt: Date.now() + (ttlInSeconds * 1000)
  }

  localStorage.setItem(key, JSON.stringify(item))
}

/**
 * Fetches an item from the localstorage.
 * It only returns the data if the TTL has not expired yet.
 * 
 * @param key The name of the item in localstorage
 */
const get = (key: string) => {
  const itemString = localStorage.getItem(key)
  if (!itemString) {
    return null
  }
  const item = JSON.parse(itemString)
  if (Date.now() > item.expiresAt) {
    localStorage.removeItem(key)
    return null
  }

  return item.data
}

const clear = (key: string) => localStorage.removeItem(key)

export default {
  get,
  set,
  clear
}
