import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Container from './Container'
import FooterLinks from '../footer/FooterLinks'
import ExternalLogos from '../footer/ExternalLogos'
import Translate from '../typography/Translate';
import SubscriptionSection from '../subscription/SubscriptionSection'

const FooterWrapper = styled.div`
  padding: ${({ theme }) => theme.padding.lg} 0;
  background-color: #f0f1f6;
  width: 100%;
`

const CopyrightFooter = styled.div`
  padding: ${({ theme }) => theme.padding.md} 0;
  text-align: center;
  background-color: #fff;
  width: 100%;
`

const Footer: React.FC = () => (
  <footer>
    <SubscriptionSection />
    <FooterWrapper>
      <Container>
        <ExternalLogos />
        <FooterLinks />
      </Container>
    </FooterWrapper>
    <CopyrightFooter>
      <Container>
        <Translate id="title.libema_footer" values={{ account: 'dierentuintickets.nl' }} />&nbsp;
        <a href="https://www.libema.nl/" target="_blank">Libéma</a>.
      </Container>
    </CopyrightFooter>
  </footer>
)

export default Footer
