import React from 'react'
import { hydrate } from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ensureReady, After } from '@jaredpalmer/after'
import * as Sentry from '@sentry/react'
import routes from './routes'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://aa149c7fc1b8403abd03b080f02aa7ef@o204295.ingest.sentry.io/5428164',
    environment: process.env.RAZZLE_STAGE,
    whitelistUrls: [/https?:\/\/((testing|www)\.)?dierentuintickets\.nl/]
  })
}

ensureReady(routes).then(data => hydrate(
  <BrowserRouter>
    <After data={data} routes={routes} />
  </BrowserRouter>,
  document.getElementById('root')
))

if (module.hot) {
  module.hot.accept()
}
