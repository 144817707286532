import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { media } from 'styled-bootstrap-grid'
import LinkIcon from '@fortawesome/fontawesome-free/svgs/solid/external-link-alt.svg'
import PrimaryButton from './PrimaryButton'

const Button = styled(PrimaryButton)`
  display: block;

  ${media.tablet`
    display: inline-block;
  `}

  svg {
    fill: #fff;
    width: 1em;
    height: 1em;
    top: .125em;
    position: relative;
  }
`

interface CheckoutButtonProps {
  to: string
}

const CheckoutButton: React.FC<CheckoutButtonProps> = ({ children, to }) => (
  <Button as="a" href={to}>
    {children || 'Bestel tickets'}&nbsp;&nbsp;
    <LinkIcon />
  </Button>
)

export default CheckoutButton
