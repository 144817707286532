import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import HomeIcon from '@fortawesome/fontawesome-free/svgs/solid/home.svg'
import ChevronRight from '@fortawesome/fontawesome-free/svgs/solid/chevron-right.svg'
import { media } from 'styled-bootstrap-grid'

interface BreadCrumb {
  label: string
  slug: string | false
}

interface BreadcrumbsProps {
  parts?: BreadCrumb[]
}

const BreadcrumbList = styled.ol`
  display: flex;
  width: 100%;
  height: 50px;
  background: ${({ theme }) => theme.color.greenLight };
  color: ${({ theme }) => theme.color.secondary };
  align-items: center;
  padding: 1rem;

  ${({ theme }) => media.tablet`
    padding: 0.5rem 2rem;
    border-radius: ${theme.borderRadius.default} ${theme.borderRadius.default} 0 0;
  `}
`

const BreadcrumbsSeparator = styled(ChevronRight)`
  color: ${({ theme }) => theme.color.secondary};
  width: 0.5rem;
  fill: ${({ theme }) => theme.color.secondary};
  margin: 0 0.75rem;
`

const Home = styled(HomeIcon)`
  cursor: pointer;
  width: 1rem;
  fill: ${({ theme }) => theme.color.secondary}
`

const BreadcrumbLink = styled(Link)`
  color: ${({ theme }) => theme.color.secondary};
  &:visited {
    color: ${({ theme }) => theme.color.secondary};
  }
`

const Breadcrumbs = ({ parts }: BreadcrumbsProps) => (
  <BreadcrumbList itemScope={true} itemType="https://schema.org/BreadcrumbList">
    <li
      itemProp="itemListElement"
      itemScope={true}
      itemType="http://schema.org/ListItem"
    >
      <Link to="/" itemProp="item">
        <Home />
      </Link>
      <BreadcrumbsSeparator/>
    </li>
    {parts?.map((part, i) => (
      <li
        key={part.label}
        itemProp="itemListElement"
        itemScope={true}
        itemType="http://schema.org/ListItem"
      >
        {part.slug === false && (
          <span>{part.label}</span>
        )}
        {part.slug !== false && (
          <BreadcrumbLink
            to={part.slug}
            itemProp="item"
          >
            {part.label}
          </BreadcrumbLink>
        )}
        {i < parts.length - 1 && (
          <BreadcrumbsSeparator />
        )}
      </li>
    ))}
  </BreadcrumbList>
)

export default Breadcrumbs
