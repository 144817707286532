import React, { ReactNode, useState } from 'react'
import styled from 'styled-components'
import { Formik, Form, Field } from 'formik'
import P from '../typography/P'
import TextField from '../form/fields/TextField'
import TextAreaField from '../form/fields/TextAreaField'
import Row from '../layout/Row'
import Col from '../layout/Col'
import SubmitButton from '../buttons/SubmitButton'
import { postContact } from '../../api/contactApi'
import ErrorMessage from '../form/ErrorMessage'
import { media } from 'styled-bootstrap-grid'
import InlineLoader from '../loaders/InlineLoader'
import * as Yup from 'yup'
import { OptionType } from '../../pages/ContactPage'

const FormGroup = styled.div`
  margin-bottom: ${({ theme }) => theme.margin.md};
  ${media.tablet`
    margin-bottom: 10;
    margin-top: 10;
  `}
`

const ErrorComponent = styled.p`
  color: ${({ theme }) => theme.color.error};
`

const SuccessComponent = styled.p`
  color: ${({ theme }) => theme.color.success};
`

interface ContactFormValues {
  email: string
  firstname: string
  phonenumber: string
  comment: string
}

const ContactSchema = Yup.object().shape({
  firstname: Yup.string().min(2, 'Minimale lengte is 2 karakters').max(50, 'De maximale lengte is 50 karakters').required('Dit veld is verplicht'),
  phonenumber: Yup.string().matches(/^[0-9+]{10,13}$/, { excludeEmptyString: true, message: 'Dit is geen geldig telefoonnummer' }),
  email: Yup.string().email('Dit is geen geldig e-mailadres').required('Dit veld is verplicht'),
  comment: Yup.string().min(5, 'De minimale lengte is 5 karakters').required('Dit veld is verplicht'),
})

const initialValues = { email: '', firstname: '', phonenumber: '', comment: '' }

type ContactFormProps = {
  selectedContact: OptionType
  children?: ReactNode
}

const ContactForm: React.FC<ContactFormProps> = ({ selectedContact }) => {
  const [submitSuccess, setSubmitSuccess] = useState('')

  return (
    <Formik<ContactFormValues>
      initialValues={initialValues}
      validationSchema={ContactSchema}
      onSubmit={({ email, firstname, comment, phonenumber }, formikHelpers) => {
        return postContact(selectedContact.label, email, firstname, comment, phonenumber)
          .then((response) => {
            const success = response.data.success
            if (!success) {
              setSubmitSuccess('fail')
            } else {
              setSubmitSuccess('success')
              formikHelpers.resetForm()
            }
          })
          .catch((error) => {
            setSubmitSuccess('fail')
            console.error(error)
          })
      }}>
      {({ isSubmitting }) => (
        <Form>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Field component={TextField} name="firstname" type="text" placeholder="Voornaam *" />
                <ErrorMessage name="firstname" />
              </FormGroup>
              <FormGroup>
                <Field component={TextField} name="phonenumber" type="text" placeholder="Telefoonummer" />
                <ErrorMessage name="phonenumber" />
              </FormGroup>
              <FormGroup>
                <Field component={TextField} name="email" type="email" placeholder="E-mailadres *" />
                <ErrorMessage name="email" />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                {/* font does not match in the text area field >( */}
                <Field component={TextAreaField} name="comment" rows="7" placeholder="Vragen en/of opmerkingen *" />
                <ErrorMessage name="comment" />
              </FormGroup>
              <SubmitButton type="submit" disabled={isSubmitting}>
                {isSubmitting && <InlineLoader />}
                {!isSubmitting && <P>VERSTUUR</P>}
              </SubmitButton>
              {submitSuccess === 'fail' && (
                <ErrorComponent>Het verzenden is niet gelukt. Probeer het opnieuw of neem telefonisch contact met ons op.</ErrorComponent>
              )}

              {submitSuccess === 'success' && <SuccessComponent>Het formulier is succesvol verzonden. We nemen z.s.m. contact met je op.</SuccessComponent>}
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
